import React from "react"
import { Link } from "gatsby"
import { graphql } from "gatsby"
import _ from "lodash"
import { Container } from "../components/lib/Container"
import CallToAction from "../components/sections/CallToActionSection"
import HeaderSection from "../components/lib/HeaderSection"
import Footer from "../components/lib/Footer"

import photo1 from "./../../static/bernat_jimenez_de_cisneros_puig.jpg"
import photo2 from "./../../static/guillermo_castro.jpg"

export default function Suscripcion({ data, location }) {
  const authors = data.allAuthorsYaml.nodes

  return (
    <>
      <Container className="!pb-0">
        <HeaderSection
          title="Autores"
          subtitle="Aunamos esfuerzos"
          text="Te ofrecemos en un mismo espacio los trabajos de investigación de varios autores, facilitando la interacción de estos contenidos y promoviendo la continuidad de su labor docente e investigadora, siempre desde el rigor científico y el respeto a la idiosincrasia de cada autor."
        />
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-10 max-w-screen-xl mx-auto">
          {authors.map((author, index) => (
            <Link
              key={author.id}
              to={`/${_.kebabCase(author.name)}`}
              class="flex flex-col border rounded-lg p-5 max-w-xl gap-2"
            >
              <img
                class="rounded-full mb-5 w-32 h-32 mx-auto self-start"
                src={index === 0 ? photo1 : photo2}
                alt=""
              />
              <div class="text-center space-y-2">
                <h2 class="text-xl font-bold">{author.name}</h2>
                <p
                  class="text-sm"
                  dangerouslySetInnerHTML={{
                    __html: author.about.substring(0, 400) + `... <b>→</b>`,
                  }}
                />
              </div>
            </Link>
          ))}
        </div>
      </Container>
      <CallToAction />
      <Footer />
    </>
  )
}

export const query = graphql`
  query {
    allAuthorsYaml {
      nodes {
        id
        about
        name
        role
        image
      }
    }
  }
`
